import {
    Box,
    Divider,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridCardFlatLabels } from "../../../../common/components";
import { VerticalBarChart } from "../../Overview/components/VerticalBarChart";
import { useEffect, useState } from "react";
import { constants } from "../../../../config/constants";

const fakeData = [
    {
        category_name: "Soup of the day",
        total_count: 60,
        total_sales: "70",
    },
    {
        category_name: "Ice cream",
        total_count: 4979,
        total_sales: "10",
    },
    {
        category_name: "Cheesecake",
        total_count: 5393,
        total_sales: "50",
    },
    {
        category_name: "Steak",
        total_count: 521,
        total_sales: "80",
    },
    {
        category_name: "Greek salad",
        total_count: 3668,
        total_sales: "20",
    },
    {
        category_name: "Turky wrap",
        total_count: 7310,
        total_sales: "10",
    },
    {
        category_name: "Macaroni",
        total_count: 144,
        total_sales: "10",
    },
    {
        category_name: "Fries",
        total_count: 273,
        total_sales: "10",
    },
    {
        category_name: "Spring rolls",
        total_count: 12096,
        total_sales: "30",
    },
    {
        category_name: "Pizza",
        total_count: 3693,
        total_sales: "30",
    },
    {
        category_name: "Caesar salad",
        total_count: 4849,
        total_sales: "50",
    },
    {
        category_name: "Mozzarella sticks",
        total_count: 3268,
        total_sales: "5",
    },
];

const CustomizedYAxisTick = (props) => {
    const { t } = useTranslation();
    const { x, y, payload } = props;

    return (
        <text
            x={x - 10}
            y={y + 30}
            dx={-45}
            textAnchor="middle"
            fill="#202020"
            fontSize={12}
            fontWeight="bold"
        >
            {payload.value.split("_")[0]}
        </text>
    );
};

const Products = ({ data = fakeData }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [categoryData, setCategoryData] = useState([]);

    useEffect(() => {
        const categoryTotal = getTotalCount(data);
        const category_data = data
            .map((order, index) => ({
                name: order.category_name,
                value: +order.total_sales,
                fill: "#5BCFDE",
            }))
            .sort((a, b) => b.value - a.value);

        setCategoryData(category_data);
    }, []);

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count;
    }

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {/* {t("overview.categories")} */}
                    Products
                </Typography>

                <Typography color="#475569">
                    N° of products to be sold <b>355</b>
                </Typography>
            </Box>

            <Divider />

            <Stack>
                <Box width="100%" height="700px" px={6}>
                    <VerticalBarChart
                        data={categoryData}
                        barSize={20}
                        margin={{
                            top: 20,
                            right: isArabic ? 100 : 0,
                            bottom: 20,
                            left: isArabic ? 0 : 100,
                        }}
                        yAxisProps={{
                            tick: <CustomizedYAxisTick />,
                        }}
                    />
                </Box>
            </Stack>
        </Stack>
    );
};

export { Products };
