import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
    ButtonSelect,
    FilterSelect,
    // ButtonSelect,
    GridCard,
    PageHeader,
} from "../../../../common/components";
import { TotalSalesData } from "./TotalSalesData";
import { HistoryData } from "./HistoryData";
import { getUserStorage } from "../../../../common/utils";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMutation } from "@tanstack/react-query";
import { services } from "../../services";

const ForcastHistory = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch, setSelectedBranch] = useState(allBranchesOption.id);

    const filterItems = [
        { label: t("overview.today"), value: "Today", key: "type" },
    ];
    const [orderType, setOrderType] = useState(filterItems[0].value);

    // const { mutate: getForcastingData, isLoading } = useMutation({
    //     mutationFn: services.getForcastingData,
    //     onSuccess: (res) => {
    //         if (res.data.response && !res.data.error) {
    //             console.log(res.data);
    //         }

    //         // if (res.data.error) {
    //         //     toast.error(res.data.message, {
    //         //         hideProgressBar: true,
    //         //     });
    //         // }
    //     },
    //     onError: (err) => console.log(err),
    // });

    // useEffect(() => {
    //     getForcastingData();
    // }, []);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <PageHeader title={"History Sales"} />

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography fontSize="15px" color="#202020">
                    Updated today 21/08/2023 at 02:13 PM
                </Typography>

                <Box display="flex" alignItems="center" gap={4}>
                    <ButtonSelect
                        value={selectedBranch}
                        // onChange={handleBranchChange}
                        options={[allBranchesOption, ...allBranches]}
                    />

                    <FilterSelect
                        value={orderType}
                        label="Today"
                        // label={t("common.order_type")}
                        items={filterItems}
                        // onChange={({ value }) => handleFilterChange(value)}
                        icon={KeyboardArrowDownIcon}
                    />

                    <Button
                        sx={{
                            textTransform: "capitalize",
                            borderRadius: 8,
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0 1px 3px hsla(220,6%,43%,0.2)",
                            border: "none",
                            "&:hover": {
                                border: "none",
                            },
                        }}
                        variant="outlined"
                    >
                        {t("common.export")}
                    </Button>
                </Box>
            </Box>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <GridCard
                    padding={0}
                    height={372}
                    boxStyles={{ minHeight: "466px", height: "fit-content" }}
                    fullWidth
                >
                    <TotalSalesData />
                </GridCard>
            </Grid>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <GridCard
                    padding={0}
                    height={372}
                    boxStyles={{ minHeight: "466px", height: "fit-content" }}
                    fullWidth
                >
                    <HistoryData />
                </GridCard>
            </Grid>
        </Stack>
    );
};

export { ForcastHistory };
