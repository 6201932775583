import { useMemo } from "react";
import { getUserStorage } from "../../utils";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const useSideNavMenuItems = () => {
    const { pathname } = useLocation();

    const { t } = useTranslation();

    const user = getUserStorage();
    const isBasicPlan =
        user?.merchant?.subscription === 2 ||
        user?.merchant?.subscription === 4;
    const isOwner = user.role === 1;
    const isGroupOwner = user.role === 9;
    // Like for like
    const hasLikeForLIkeAccess =
        isOwner || isGroupOwner || user.access.includes("Like-for-like");
    // Basket
    const hasBasketAccess =
        isOwner || isGroupOwner || user.access.includes("Bought Together");
    // Sales
    const hasSalesAccess =
        isOwner || isGroupOwner || user.access.includes("Sales");
    const hasSalesInsightsAccess =
        isOwner || isGroupOwner || user.access.includes("Sales Insights");
    const hasSalesPerformanceAccess =
        isOwner || isGroupOwner || user.access.includes("Sales Performance");
    const hasSalesAggregatorsAccess =
        isOwner || isGroupOwner || user.access.includes("Sales Aggregators");
    // Customer
    const hasCustomersAccess =
        isOwner || isGroupOwner || user.access.includes("Customers");
    const hasCustomersDataAccess =
        isOwner || isGroupOwner || user.access.includes("Customers Data");
    const hasCustomersInsightsAccess =
        isOwner || isGroupOwner || user.access.includes("Customer Insights");
    // Menu
    const hasMenuAccess =
        isOwner || isGroupOwner || user.access.includes("Menu");
    const hasMenuEngineeringAccess =
        isOwner || isGroupOwner || user.access.includes("Menu Engineering");

    const sideNavMenuItems = useMemo(
        () => [
            {
                key: "sales",
                isDisabled: !hasSalesAccess,
                to: hasSalesInsightsAccess ? "/sales/insights" : null,
                label: t("sales.sales"),
                iconPath: pathname.includes("sales")
                    ? "/ic_sales_selected.svg"
                    : "/ic_sales.svg",
                subItems: [
                    {
                        label: t("sales.insights"),
                        to: "/sales/insights",
                        iconPath: "/sales_icon.svg",
                        isDisabled: !hasSalesInsightsAccess,
                    },
                    {
                        label: t("sales.performance"),
                        to: "/sales/performance",
                        iconPath: "/sales performance.svg",
                        isDisabled: !hasSalesPerformanceAccess,
                    },
                    {
                        label: t("sales.delivery"),
                        to: "/sales/aggregators",
                        iconPath: "/sales_aggregators.svg",
                        isDisabled: !hasSalesAggregatorsAccess,
                    },
                ],
            },
            {
                key: "customers",
                isDisabled: !hasCustomersAccess,
                label: t("customer.customers"),
                to: hasCustomersInsightsAccess ? "/customers/insights" : null,
                iconPath: pathname.includes("customers")
                    ? "/ic_customers selected.svg"
                    : "/ic_customers.svg",
                subItems: [
                    {
                        label: t("customer.insights"),
                        to: "/customers/insights",
                        iconPath: "/customer_insights.svg",
                        isDisabled: !hasCustomersInsightsAccess,
                    },
                    {
                        label: t("customer.data"),
                        to: "/customers/data",
                        iconPath: "/customers_data.svg",
                        isDisabled: !hasCustomersDataAccess,
                    },
                ],
            },
            {
                key: "menu",
                isDisabled: isBasicPlan || !hasMenuAccess,
                iconPath: isBasicPlan
                    ? "/ic_product_disabled.svg"
                    : pathname.includes("menu")
                    ? "/ic_products selected.svg"
                    : "/ic_product.svg",
                to: hasMenuEngineeringAccess
                    ? "/menu/insights"
                    : "/menu/products",
                label: t("menu.menu_engineering"),
                subItems: [
                    {
                        label: t("menu.insights"),
                        to: "/menu/insights",
                        iconPath: "/menu_insights_icon.svg",
                        isDisabled: !hasMenuEngineeringAccess,
                    },
                    {
                        label: t("menu.prodcuts"),
                        to: "/menu/products",
                        iconPath: "/menu_products.svg",
                    },
                ],
            },
            {
                key: "basket",
                isDisabled: isBasicPlan || !hasBasketAccess,
                iconPath: isBasicPlan
                    ? "/ic_bought_disabled.svg"
                    : pathname.includes("basket")
                    ? "/ic_bought_selected.svg"
                    : "/ic_bought.svg",
                to: "/basket/freq",
                label: t("basket.bught_together"),
                subItems: [
                    {
                        label: t("basket.bught_together"),
                        to: "/basket/freq",
                        iconPath: "/freq_bought.svg",
                    },
                    {
                        label: t("basket.basket"),
                        to: "/basket/product",
                        iconPath: "/basket_product.svg",
                    },
                ],
            },
            {
                key: "likeForLike",
                isDisabled: isBasicPlan || !hasLikeForLIkeAccess,
                iconPath: isBasicPlan
                    ? "/ic_like for like_disabled.svg"
                    : pathname.includes("likeForLike")
                    ? "/ic_like for like_selected.svg"
                    : "/ic_like for like.svg",
                to: "/likeForLike/products",
                label: t("likeForLike.likeForLike"),
                subItems: [
                    {
                        label: t("likeForLike.branch_like"),
                        to: "/likeForLike/branches",
                        // iconPath: null,
                        iconPath: "/branches_like.svg",
                    },
                    {
                        label: t("likeForLike.product_like"),
                        to: "/likeForLike/products",
                        iconPath: "/menu_products.svg",
                    },
                    {
                        label: t("likeForLike.category_like"),
                        to: "/likeForLike/categories",
                        iconPath: "/categories_like.svg",
                    },
                ],
            },
            {
                key: "pixel",
                isBeta: true,
                iconPath: pathname.includes("pixel")
                    ? "/forcast_history_outline.svg"
                    : "/forcast_history_outline.svg",
                to: "/pixel",
                label: t("common.pixel"),
                subItems: [
                    {
                        label: t("common.key_events"),
                        to: "/pixel",
                        iconPath: "/forcast_history.svg",
                        isDisabled: false,
                        isBeta: true,
                    },
                    {
                        label: t("common.offer_desinger"),
                        to: "/pixel",
                        iconPath: "/forcast_overview.svg",
                        isDisabled: true,
                        isSoon: true,
                    },
                ],
            },
            {
                key: "forcast",
                iconPath: pathname.includes("assistant")
                    ? "/assistant.svg"
                    : "/assistant.svg",
                to: "/forcast/overview",
                label: t("common.ai"),
                subItems: [
                    {
                        label: t("settings.forcast_overview"),
                        to: "/forcast/overview",
                        iconPath: "/forcast_overview.svg",
                        isDisabled: false,
                    },
                    {
                        label: t("settings.assistant"),
                        to: "/assistant",
                        iconPath: pathname.includes("assistant")
                            ? "/assistant.svg"
                            : "/assistant.svg",
                    },
                ],
            },
        ],
        [
            hasBasketAccess,
            hasCustomersAccess,
            hasCustomersDataAccess,
            hasCustomersInsightsAccess,
            hasLikeForLIkeAccess,
            hasMenuAccess,
            hasMenuEngineeringAccess,
            hasSalesAccess,
            hasSalesAggregatorsAccess,
            hasSalesInsightsAccess,
            hasSalesPerformanceAccess,
            isBasicPlan,
            pathname,
            t,
        ]
    );

    const groupOwnerNavMenuItems = useMemo(
        () => [
            {
                key: "settings",
                iconPath: pathname.includes("settings")
                    ? "/ic_settings_selected.svg"
                    : "/ic_settings.svg",
                to: "/settings/owners",
                label: t("settings.settings"),
                subItems: [
                    {
                        label: t("settings.my_team"),
                        to: "/settings/owners",
                        iconPath: "/my_team.svg",
                    },
                ],
            },
        ],
        [pathname, t]
    );

    const ownerNavMenuItems = useMemo(
        () => [
            {
                key: "settings",
                iconPath: pathname.includes("settings")
                    ? "/ic_settings_selected.svg"
                    : "/ic_settings.svg",
                to: "/settings/branches",
                label: t("settings.settings"),
                subItems: [
                    {
                        label: t("settings.my_branches"),
                        to: "/settings/branches",
                        iconPath: "/branches_icon.svg",
                    },
                    {
                        label: t("settings.my_team"),
                        to: "/settings/team",
                        iconPath: "/my_team.svg",
                    },
                    {
                        label: t("settings.subscriptions"),
                        to: "/settings/subscription",
                        iconPath: "/ic_subscription.svg",
                    },
                ],
            },
        ],
        [pathname, t]
    );

    return { sideNavMenuItems, groupOwnerNavMenuItems, ownerNavMenuItems };
};

export { useSideNavMenuItems };
