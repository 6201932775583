import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ErrorPage from "./App/error-page";
import { MuiThemeProvider } from "./config/theme";
import { Login } from "./Auth/components/Login";
import { Register } from "./Auth/components/Register";
import { SharedLayout } from "./common/components/SharedLayout";
import { Overview } from "./App/manager/Overview";
import { OverviewPDF } from "./App/manager/Overview/pdf/OverviewPDF";
import { Insights, Products, Product } from "./App/manager/MenuEngineering";
import { BasketFreq, BasketProduct } from "./App/manager/Basket";
import {
    Customer,
    CustomersData,
    CustomersInsights,
    // CustomersPerformance,
} from "./App/manager/Customers";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { Integrations } from "./App/manager/Integrations/Integrations";
import { ToastContainer } from "react-toastify";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import {
    SalesAggregators,
    SalesInsights,
    SalesPerformance,
} from "./App/manager/Sales";
import { MyBranches, MyTeam, Subscription } from "./App/Owner/Settings";
import { CreateBranch } from "./App/Owner/CreateBranch";
import { LikeForLikeProducts } from "./App/manager/LikeForLike/Products";
import { LikeForLikeCategories } from "./App/manager/LikeForLike/Categories/Categories";
import { CreateManager } from "./App/Owner/CreateManager/CreateManager";
import { WelcomePage } from "./Auth/components/Welcome/Welcome";
import { LikeForLikeBranches } from "./App/manager/LikeForLike/Branches/Branches";
import { constants } from "./config/constants";
import { Account } from "./App/manager/Account/Account";
import { PaymentSuccess } from "./App/Owner/Settings/Subscription/PaymentSuccess";
import { AddBranch } from "./App/Owner/CreateBranch/AddBranch";
import { PaymentBranchSuccess } from "./App/Owner/Settings/Subscription/PaymentBranchSuccess";
import { PaymentUserSuccess } from "./App/Owner/Settings/Subscription/PaymentUserSuccess";
import {
    ForcastHistory,
    ForcastLab,
    ForcastOverview,
} from "./App/manager/Forcast";
import { ForcastHours } from "./App/manager/Forcast/Hours/ForcastHours";
import { SalesInsightsPDF } from "./App/manager/Sales/Insights/pdf/InsightsPDF";
import { PerformancePDF } from "./App/manager/Sales/Performance/pdf/PerformancePDF";
import { AggregatorsPDF } from "./App/manager/Sales/Aggregators/pdf/AggregatorsPDF";
import { CustomersInsightsPDF } from "./App/manager/Customers/Insights/pdf/CustomersInsightsPDF";
import { CustomersDataPDF } from "./App/manager/Customers/Data/pdf/CustomersDataPDF";
import { MenuInsightsPDF } from "./App/manager/MenuEngineering/Insights/pdf/MenuInsightsPDF";
import { MenuProductsPDF } from "./App/manager/MenuEngineering/Products/pdf/MenuProductsPDF";
import { BasketFreqPDF } from "./App/manager/Basket/Freq/pdf/BasketFreqPDF";
import { BasketProductPDF } from "./App/manager/Basket/Product/pdf/BasketProductPDF";
import { Pixel } from "./App/manager/Pixel/Pixel";
import { BallurhAssistant } from "./App/manager/BallurhAssistant/BallurhAssistant";
import { ProfitAndLosePage } from "./App/manager/Sales/Aggregators/ProfitAndLosePage";
import { CreateLoseProfit } from "./App/manager/Sales/Aggregators/ProfitAndLoseCreate";
import { CreateOwner } from "./App/Owner/CreateOwner";
import { Owners } from "./App/Owner/Settings/Owners";
import { CreateGroupManager } from "./App/Owner/CreateGroupmanager";

const queryClient = new QueryClient();

i18n.init({
    lng: localStorage.getItem(constants.storage.language) || "en",
    resources: {
        en: {
            translation: require("./config/locales/translate_en.json"),
        },
        ar: {
            translation: require("./config/locales/translate_ar.json"),
        },
    },
    interpolation: {
        escapeValue: false,
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <I18nextProvider i18n={i18n}>
        <MuiThemeProvider>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/overviewPDF" element={<OverviewPDF />} />
                        <Route
                            path="/BasketFreqPDF"
                            element={<BasketFreqPDF />}
                        />
                        <Route
                            path="/BasketProductPDF"
                            element={<BasketProductPDF />}
                        />
                        <Route
                            path="/MenuProductsPDF"
                            element={<MenuProductsPDF />}
                        />
                        <Route
                            path="/MenuInsightsPDF"
                            element={<MenuInsightsPDF />}
                        />
                        <Route
                            path="/aggregatorsPDF"
                            element={<AggregatorsPDF />}
                        />
                        <Route
                            path="/customerInsightsPDF"
                            element={<CustomersInsightsPDF />}
                        />
                        <Route
                            path="/CustomersDataPDF"
                            element={<CustomersDataPDF />}
                        />
                        <Route
                            path="/performancePDF"
                            element={<PerformancePDF />}
                        />
                        <Route
                            path="/salesInsightsPDF"
                            element={<SalesInsightsPDF />}
                        />
                        <Route path="/" element={<SharedLayout />}>
                            <Route path="/" element={<Overview />} />
                            <Route path="/overview" element={<Overview />} />
                            <Route
                                path="/integrations"
                                element={<Integrations />}
                            />
                            <Route path="/account" element={<Account />} />
                            <Route
                                path="/sales/insights"
                                element={<SalesInsights />}
                            />
                            <Route
                                path="/sales/performance"
                                element={<SalesPerformance />}
                            />
                            <Route
                                path="/sales/aggregators"
                                element={<SalesAggregators />}
                            />
                            <Route
                                path="/pl/history"
                                element={<ProfitAndLosePage />}
                            />
                            <Route
                                path="/pl/create"
                                element={<CreateLoseProfit />}
                            />
                            <Route
                                path="/menu/insights"
                                element={<Insights />}
                            />
                            <Route
                                path="/menu/products"
                                element={<Products />}
                            />
                            <Route
                                path="/menu/products/:productId"
                                element={<Product />}
                            />
                            <Route
                                path="/basket/freq"
                                element={<BasketFreq />}
                            />
                            <Route
                                path="/basket/product"
                                element={<BasketProduct />}
                            />
                            <Route
                                path="/likeForLike/products"
                                element={<LikeForLikeProducts />}
                            />
                            <Route
                                path="/likeForLike/categories"
                                element={<LikeForLikeCategories />}
                            />
                            <Route
                                path="/likeForLike/branches"
                                element={<LikeForLikeBranches />}
                            />

                            <Route
                                path="/forcast/history"
                                element={<ForcastHistory />}
                            />
                            <Route
                                path="/forcast/overview"
                                element={<ForcastOverview />}
                            />
                            <Route
                                path="/forcast/lab"
                                element={<ForcastLab />}
                            />
                            <Route
                                path="/forcast/hours"
                                element={<ForcastHours />}
                            />

                            <Route path="/pixel" element={<Pixel />} />
                            <Route
                                path="/assistant"
                                element={<BallurhAssistant />}
                            />

                            {/* Owner Settings */}
                            <Route
                                path="/settings/branches"
                                element={<MyBranches />}
                            />
                            <Route
                                path="/settings/branches/new"
                                element={<CreateBranch />}
                            />
                            <Route
                                path="/settings/branches/add"
                                element={<AddBranch />}
                            />
                            <Route
                                path="/settings/team/new"
                                element={<CreateManager />}
                            />
                            <Route
                                path="/settings/owners/new"
                                element={<CreateOwner />}
                            />
                            <Route
                                path="/settings/owners/newGroup"
                                element={<CreateGroupManager />}
                            />
                            <Route
                                path="/settings/team/edit"
                                element={<CreateManager />}
                            />
                            <Route path="/settings/team" element={<MyTeam />} />
                            <Route
                                path="/settings/owners"
                                element={<Owners />}
                            />
                            <Route
                                path="/settings/subscription"
                                element={<Subscription />}
                            />

                            <Route
                                path="/customers/insights"
                                element={<CustomersInsights />}
                            />
                            {/* <Route path="/customers/performance" element={<CustomersPerformance />} /> */}
                            <Route
                                path="/customers/data"
                                element={<CustomersData />}
                            />
                            <Route
                                path="/customers/data/:customerId/:ltv/:total/:startDate/:endDate"
                                element={<Customer />}
                            />
                        </Route>
                        <Route path="login" element={<Login />} />
                        <Route path="signup" element={<Register />} />
                        <Route
                            path="foodics-success"
                            element={<WelcomePage />}
                        />
                        <Route
                            path="payment-success"
                            element={<PaymentSuccess />}
                        />
                        <Route
                            path="payment-branch-success"
                            element={<PaymentBranchSuccess />}
                        />
                        <Route
                            path="payment-user-success"
                            element={<PaymentUserSuccess />}
                        />
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>

                    <ToastContainer />
                </BrowserRouter>
            </QueryClientProvider>
        </MuiThemeProvider>
    </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
