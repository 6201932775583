import {
    Autocomplete,
    Box,
    Button,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useMemo, useState } from "react";

function generateArrayOfCategories() {
    const labels = ["Pizza", "Sandwich", "Pasta", "Dessert"];
    const arrayOfObjects = [];

    for (let i = 0; i < 4; i++) {
        const randomLabel = labels[i];

        const object = {
            id: i,
            label: randomLabel,
            value: randomLabel,
        };

        arrayOfObjects.push(object);
    }

    return arrayOfObjects;
}

const CategoryForcast = ({ onForcast }) => {
    const fakeCategroies = useMemo(() => generateArrayOfCategories(), []);
    const [selectedOption, setSelectedOption] = useState(null);

    return (
        <Stack p={6} pt={8} spacing={3}>
            <Typography color="#06262D">
                Please select the category name
            </Typography>

            <Box display="flex" alignItems="center" gap={6}>
                <Box width={400}>
                    <Autocomplete
                        autoComplete="off"
                        size="small"
                        value={selectedOption}
                        onChange={(e, value) => setSelectedOption(value)}
                        disablePortal
                        options={fakeCategroies}
                        sx={{ width: 400 }}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                placeholder="Select category"
                                autoComplete="off"
                                {...params}
                                sx={{ height: 32 }}
                                label={""}
                                InputLabelProps={{
                                    style: {
                                        color: "#6B7280",
                                    },
                                }}
                            />
                        )}
                    />
                </Box>

                <Button
                    sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#00C08B",
                        textTransform: "capitalize",
                        borderRadius: "6px",
                        px: 4,
                        "&:hover": {
                            backgroundColor: "#00C08B",
                            color: "#FFFFFF",
                        },
                        position: "relative",
                        top: "4px",
                    }}
                    onClick={() => onForcast(selectedOption)}
                >
                    Forecast Now
                </Button>
            </Box>
        </Stack>
    );
};

export { CategoryForcast };
