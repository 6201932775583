import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -30%)",
    width: 510,
    height: 230,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 10,
    borderRadius: 4,
};

const DeleteOutlier = ({
    isOpen,
    handleClose,
    handleDeleteOutlier,
    outlier,
}) => {
    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={style}>
                <Box display="flex" gap={3}>
                    <Box position="relative" top={-4}>
                        <img src="/alert_red.svg" alt="warning" />
                    </Box>
                    <Stack spacing={5} flex={1}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                align="left"
                            >
                                Delete Outlier
                            </Typography>
                        </Box>
                        <Typography
                            id="modal-modal-title"
                            fontSize={14}
                            align="left"
                            color="#6B7280"
                        >
                            Are you sure you want to delete this outlier? It
                            will be deleted immediately. You can’t undo this
                            action.
                        </Typography>
                    </Stack>
                </Box>

                <Box display="flex" mt={10} justifyContent="flex-end" gap={5}>
                    <Button
                        variant="outlined"
                        sx={{
                            textTransform: "capitalize",
                            borderRadius: "6px",
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            textTransform: "capitalize",
                            borderRadius: "6px",
                            width: "fit-content",
                            color: "white",
                            backgroundColor: "#DC2626",
                            border: "1px solid #EEEFF4",
                            "&:hover": {
                                border: "1px solid #EEEFF4",
                                backgroundColor: "#DC2626",
                            },
                        }}
                        onClick={() => handleDeleteOutlier(outlier)}
                    >
                        Delete
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export { DeleteOutlier };
