import {
    Autocomplete,
    Box,
    Button,
    Divider,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { ProuctForcast } from "./components/ProductForcast";
import { OrderTypeForcast } from "./components/OrderTypeForcast";
import { CategoryForcast } from "./components/CategoryForcast";

const ForcastPicker = ({ onForcast }) => {
    const [selectedCategory, setSelectedCategory] = useState("product");

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={100}
            >
                <Box display="flex" alignItems="baseline" gap={4}>
                    <Button
                        sx={{
                            color: selectedCategory === "product" && "#00C08B",
                            backgroundColor:
                                selectedCategory === "product" && "#EFFEF4",
                            "&:hover": {
                                backgroundColor: "#EFFEF4",
                            },
                            textTransform: "capitalize",
                            borderRadius: "8px",
                        }}
                        onClick={() => setSelectedCategory("product")}
                    >
                        Product Forecast
                    </Button>

                    <Button
                        sx={{
                            color: selectedCategory === "category" && "#00C08B",
                            backgroundColor:
                                selectedCategory === "category" && "#EFFEF4",
                            "&:hover": {
                                backgroundColor: "#EFFEF4",
                                color: "#00C08B",
                            },
                            textTransform: "capitalize",
                            // backgroundColor: "#5BCFDE",
                            borderRadius: "8px",
                        }}
                        onClick={() => setSelectedCategory("category")}
                    >
                        Category Forecast
                    </Button>

                    <Button
                        sx={{
                            color: selectedCategory === "order" && "#00C08B",
                            backgroundColor:
                                selectedCategory === "order" && "#EFFEF4",
                            "&:hover": {
                                backgroundColor: "#EFFEF4",
                                color: "#00C08B",
                            },
                            textTransform: "capitalize",
                            borderRadius: "8px",
                        }}
                        onClick={() => setSelectedCategory("order")}
                    >
                        Order Type Forecast
                    </Button>
                </Box>
            </Box>

            <Divider />

            {selectedCategory === "product" && (
                <ProuctForcast onForcast={onForcast} />
            )}
            {selectedCategory === "category" && (
                <CategoryForcast onForcast={onForcast} />
            )}
            {selectedCategory === "order" && (
                <OrderTypeForcast onForcast={onForcast} />
            )}
        </Stack>
    );
};

export { ForcastPicker };
