import {
    Box,
    Divider,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridCardFlatLabels } from "../../../../common/components";
import { VerticalBarChart } from "../../Overview/components/VerticalBarChart";
import { useEffect, useState } from "react";
import { constants } from "../../../../config/constants";

const fakeData = [
    {
        category_name: "Apps+Drinks",
        total_count: 60,
        total_sales: "1624.20",
    },
    {
        category_name: "Beverages-APP",
        total_count: 4979,
        total_sales: "132607.63",
    },
    {
        category_name: "Burger",
        total_count: 5393,
        total_sales: "207227.06",
    },
    {
        category_name: "Delivery Charge",
        total_count: 521,
        total_sales: "18616.74",
    },
    {
        category_name: "Lunch Offer",
        total_count: 3668,
        total_sales: "280526.61",
    },
    {
        category_name: "Sauces",
        total_count: 7310,
        total_sales: "368850.93",
    },
    {
        category_name: "Side Items",
        total_count: 144,
        total_sales: "8016.87",
    },
    {
        category_name: "Special Request",
        total_count: 273,
        total_sales: "10892.20",
    },
    {
        category_name: "Most Popular",
        total_count: 12096,
        total_sales: "333882.82",
    },
    {
        category_name: "Pizza",
        total_count: 3693,
        total_sales: "155670.12",
    },
    {
        category_name: "New offer's",
        total_count: 4849,
        total_sales: "203333.57",
    },
    {
        category_name: "Product Test",
        total_count: 3268,
        total_sales: "84658.72",
    },
];

const Categories = ({ data = fakeData }) => {
    const { t } = useTranslation();

    const [categoryData, setCategoryData] = useState([]);

    useEffect(() => {
        const categoryTotal = getTotalCount(data);
        const category_data = data.map((order, index) => ({
            name: order.category_name,
            value: Math.ceil(
                ((+order.total_sales / categoryTotal).toFixed(4) * 1000) / 10
            ),
            fill: constants.pieColors[index],
            innerRadius: (index + 1) * 10,
            outerRadius: 90 + (index + 1) * 6,
        }));

        setCategoryData(category_data);
    }, []);

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count;
    }

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {t("overview.categories")}
                </Typography>
            </Box>

            <Divider />

            <Stack>
                <GridCardFlatLabels data={categoryData} isFlat />

                <Box width="100%" height="300px" px={6}>
                    <VerticalBarChart data={categoryData} />
                </Box>
            </Stack>
        </Stack>
    );
};

export { Categories };
