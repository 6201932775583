import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridCard, PageHeader } from "../../../../common/components";
import { getUserStorage } from "../../../../common/utils";
import { useState } from "react";
import { ForcastData } from "./ForcastData";
import { SalesForcast } from "./SalesForcast";
import { ForcastPicker } from "./ForcastPicker";

const ForcastLab = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch, setSelectedBranch] = useState(allBranchesOption.id);

    const filterItems = [
        { label: t("overview.today"), value: "Today", key: "type" },
    ];

    const [forcastedItem, setForcastedItem] = useState(null);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <PageHeader title={"Forcast Lab"} />

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <GridCard
                    padding={0}
                    height={372}
                    boxStyles={{ minHeight: "310px", height: "fit-content" }}
                    fullWidth
                >
                    <ForcastPicker
                        onForcast={(item) => setForcastedItem(item)}
                    />
                </GridCard>
            </Grid>

            {forcastedItem && (
                <>
                    <Grid
                        container
                        spacing={6}
                        style={{
                            margin: isArabic ? 0 : "-24px",
                            marginTop: 2,
                            marginBottom: 2,
                        }}
                    >
                        <GridCard
                            padding={0}
                            height={372}
                            boxStyles={{
                                minHeight: "466px",
                                height: "fit-content",
                            }}
                            fullWidth
                        >
                            <SalesForcast />
                        </GridCard>
                    </Grid>

                    <Grid
                        container
                        spacing={6}
                        style={{
                            margin: isArabic ? 0 : "-24px",
                            marginTop: 2,
                            marginBottom: 2,
                        }}
                    >
                        <GridCard
                            padding={0}
                            height={372}
                            boxStyles={{
                                minHeight: "466px",
                                height: "fit-content",
                            }}
                            fullWidth
                        >
                            <ForcastData />
                        </GridCard>
                    </Grid>
                </>
            )}
        </Stack>
    );
};

export { ForcastLab };
